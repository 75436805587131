import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    TextField, Typography, Box, Grid, Button, Container,
    Checkbox, Dialog, Card, CardContent, Alert, LinearProgress,
    DialogTitle, DialogContent, DialogActions,
    RadioGroup, Radio, FormControl, FormControlLabel, CircularProgress
} from "@mui/material";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import cupon from './../../assets/img/cupon.png'
import mcdonalds from './../../assets/img/mcdonalds.png'
import mcdonalds2 from './../../assets/img/mcdonalds2.png'
import { db, firebase } from '../../db/firebase';
export const Mcdonaldspage = () => {

    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth

    const [openConfirmBuy, setOpenConfirmBuy] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingButtonBuy, setLoadingButtonBuy] = useState(false);
    const [loadingStatusBuyZapping, setLoadingStatusBuyZapping] = useState(false);

    const [isCodeRevealed, setIsCodeRevealed] = useState(false);
    const [isCodePermanentlyRevealed, setIsCodePermanentlyRevealed] = useState(false);


    const [revealProgress, setRevealProgress] = useState(0); // Progreso de la revelación
    const [timerId, setTimerId] = useState(null); // ID del temporizador

    const [redemptionCount, setRedemptionCount] = useState(0);

    const [redeemedToday, setRedeemedToday] = useState(false);

    const startReveal = () => {
        setRevealProgress(0);
        const id = setInterval(() => {
            setRevealProgress(prev => {
                if (prev >= 100) {
                    clearInterval(id);
                    setIsCodeRevealed(true); // Revela el código completamente
                    return 100;
                }
                return prev + 1; // Incrementa el progreso cada 30ms
            });
        }, 30); // Incrementa el progreso cada 30ms (total de 3 segundos para llegar a 100%)
        setTimerId(id);
    };

    const stopReveal = () => {
        clearInterval(timerId);
        if (revealProgress < 100) {
            setRevealProgress(0); // Resetea si se suelta antes de completar
        } else {
            setIsCodeRevealed(true); // Revela completamente si llegó al 100%
        }
        setTimerId(null);
    };

    const revealCode = () => {
        setIsCodeRevealed(true);
        setIsCodePermanentlyRevealed(true);
    };

    const hideCode = () => {
        if (!isCodePermanentlyRevealed) {
            setIsCodeRevealed(false);
        }
    };

    let navigate = useNavigate()

    const getCurrentRedemptionCount = async () => {
        const userId = userInfo.id;

        // Fecha y hora de hoy (00:00:00)
        const startOfToday = new Date();
        startOfToday.setHours(0, 0, 0, 0);

        // Fecha y hora del final de hoy (23:59:59)
        const endOfToday = new Date();
        endOfToday.setHours(23, 59, 59, 999);

        const startOfSeptember = new Date(new Date().getFullYear(), 8, 1); // 7 porque enero es 0, agosto es 7
        const endOfSeptember = new Date(new Date().getFullYear(), 8, 30, 23, 59, 59); // Último segundo del 31 de agosto

        if (!userId) {
            console.error("El ID de usuario es undefined");
            return;
        }

        try {
            // Consulta para verificar si hay un canje hoy
            const querySnapshotToday = await db.collection('beneficios')
                .doc('cupones')
                .collection('Mcdonalds')
                .doc('cupones_canjeados')
                .collection('lista_canjes_totales')
                .where('id', '==', userId)
                .where('created', '>=', startOfToday)
                .where('created', '<=', endOfToday)
                .get();

            const hasRedeemedToday = querySnapshotToday.size > 0;
            setRedeemedToday(hasRedeemedToday)
            console.log("¿Hay un canje para hoy?", hasRedeemedToday);
            /*  */
            const querySnapshot = await db.collection('beneficios')
                .doc('cupones')
                .collection('Mcdonalds')
                .doc('cupones_canjeados')
                .collection('lista_canjes_totales')
                .where('id', '==', userId)
                .where('created', '>=', startOfSeptember)
                .where('created', '<=', endOfSeptember)
                .get();

            const redemptionCount = querySnapshot.size;
            console.log("Cantidad de canjes actuales en octubre:", redemptionCount);

            setRedemptionCount(redemptionCount)// Retorna la cantidad de canjes
        } catch (error) {
            console.error("Error al obtener la cantidad de canjes:", error);
        }
    };

    const sendRedemptionAmount = async () => {

        if (redemptionCount < 30) {

            const newDocRef = db.collection('beneficios')
                .doc('cupones')
                .collection('Mcdonalds')
                .doc('cupones_canjeados')
                .collection('lista_canjes_totales')
                .doc();

            const uid = newDocRef.id;

            newDocRef.set({
                cedula: userInfo.cedula,
                codigoCupon: 'YIGA5OCT',
                correo: userInfo.correo,
                contrato: userInfo.NUM_CONTRATO,
                id: userInfo.id,
                nombres: userInfo.nombre,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                uid: uid
            }).then(() => {
                console.log("Documento creado con ID:", uid);
            }).catch((error) => {
                console.error("Error al crear el documento:", error);
            });
        }
    };

    useEffect(() => {
        if (isCodeRevealed) {
            sendRedemptionAmount()
        }
    }, [isCodeRevealed])

    useEffect(() => {
        getCurrentRedemptionCount()
        console.log(userInfo.estado)
    })

    return (
        <>
            <Dialog open={isLoading}>
                <DialogContent style={{ textAlign: 'center' }}>
                    <CircularProgress color="primary" style={{ marginBottom: '16px' }} />
                    <Typography variant="subtitle1">Por favor, espere...</Typography>
                </DialogContent>
            </Dialog>
            <Grid
                container
                component="main"
                alignItems="center"
                justifyContent="center"
            >
                <Box style={{ backgroundSize: '100%' }}>
                    <Container component="Box" maxWidth="lg" fullWidth>
                        {/* <Typography
                            variant='h4'
                            component='h1'
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                color: '#DB0009'
                            }}
                        >
                            <strong>MC CUPON</strong>
                        </Typography> */}
                        <Box py={1}></Box>
                        <Card /* sx={{ bgcolor: '#DB0009'}} */ sx={{ bgcolor: '#faf8df' }}>
                            <CardContent style={{ padding: "0px" }}>

                                <Box py={3} style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    background: 'red'
                                }}>
                                    <img src={mcdonalds2} width="30%" />
                                    <Typography variant='h5' style={{ color: 'white', fontWeight: 'bold' }}>MCDONALDS</Typography>
                                </Box>


                                <Box py={2} px={3}>

                                    <Box pb={2}></Box>

                                    <Typography
                                        variant='h6'
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        ¡FELICIDADES! TENEMOS UN REGALO EXCLUSIVO PARA TI
                                    </Typography>
                                    <Typography
                                        variant='h6'
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        POR SER CLIENTE YIGA5
                                    </Typography>
                                    <Box pb={1}></Box>
                                    <Typography
                                        variant='h2'
                                        style={{
                                            color: "#FFBC0F",
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <strong>15% DESCUENTO</strong>

                                    </Typography>
                                    <Box pt={1} pb={5}>
                                        <Typography
                                            variant='h6'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            EN TU PROXIMA COMPRA
                                        </Typography>
                                    </Box>

                                    <Typography
                                        variant='subtitle1'
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            color: "gray"
                                        }}
                                    >
                                        {/* TU CODIGO DE CUPON ES */}
                                        TU CÓDIGO DE DESCUENTO ES
                                    </Typography>

                                    <Box pt={1}
                                        pb={5}>
                                        <Typography
                                            variant='caption'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                color: "gray",
                                                opacity: redeemedToday ? 0 : 1 - revealProgress / 100, // Reduce la opacidad conforme progresa
                                                transition: 'opacity 0.1s ease'
                                            }}
                                        >
                                            {userInfo.estado != 'ACTIVO' ? 'PARA PODER VER EL CÓDIGO DEBE CONTAR CON AL MENOS UN SERVICIO ACTIVO' : 'MANTENGA PRESIONADO EL BOTON PARA REVELAR EL CÓDIGO'}
                                        </Typography>

                                        <Box
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                filter: redeemedToday ? `blur(0)` : `blur(${8 - (revealProgress / 12.5)}px)`, // Se reduce el blur conforme progresa
                                                opacity: redeemedToday ? 100 : revealProgress / 100, // Incrementa la opacidad conforme progresa
                                                transition: 'filter 0.1s ease, opacity 0.1s ease'
                                            }}
                                        >
                                            <Typography
                                                variant='h4'
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <strong>YIGA5OCT</strong>
                                            </Typography>
                                        </Box>
                                    </Box>
                                    {/* <Box pt={1} pb={6}>
                                        <Typography
                                            variant='h5'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <strong>YIGA5</strong>
                                        </Typography>
                                    </Box> */}


                                    <Box
                                        width="100%"
                                        borderTop="2px dashed gray"
                                        position="relative"
                                        mb={3} // Ajusta el espacio entre la línea y el botón
                                    >
                                        {/* <Box
                                            position="absolute"
                                            top="-10px"
                                            left="50%"
                                            width="20px"
                                            height="20px"
                                            bgcolor="white"
                                            borderRadius="50%"
                                            transform="translateX(-50%)"
                                            border="2px solid gray"
                                        /> */}
                                    </Box>
                                    <Box pt={2}>
                                        <LoadingButton
                                            fullWidth
                                            color="warning"
                                            variant="contained"
                                            loading={loadingStatusBuyZapping}
                                            endIcon={loadingStatusBuyZapping === true ? <SendIcon /> : ''}
                                            disabled={loadingStatusBuyZapping === true || isCodeRevealed || redeemedToday || redemptionCount >= 30 || userInfo.estado != 'ACTIVO'}
                                            loadingPosition="end"
                                            onMouseDown={startReveal}
                                            onMouseUp={stopReveal}
                                            onMouseLeave={stopReveal}
                                            onTouchStart={startReveal}
                                            onTouchEnd={stopReveal}
                                            onClick={() => setOpenConfirmBuy(true)}
                                            style={{
                                                background: `linear-gradient(to right, #abb2b9 ${revealProgress}%, #DB0009 ${revealProgress}%)`,
                                                transition: 'background 0.1s ease',
                                                ...(isCodeRevealed || (userInfo.estado != 'ACTIVO' || redeemedToday || redemptionCount >= 30) && { background: '#abb2b9' })  // Color final cuando el código está revelado
                                            }}
                                        >
                                            {isCodeRevealed ? 'CÓDIGO REVELADO' : redeemedToday ? 'CÓDIGO DEL DIA REVELADO' : redemptionCount >= 30 ? 'CÓDIGO CANJEADO MAS DE 30 VECES' : userInfo.estado != 'ACTIVO' ? 'DEBE TENER UN SERVICIO ACTIVO' : `REVELAR CÓDIGO`}
                                        </LoadingButton>
                                    </Box>

                                    {/* <Box pt={3} pb={2}>
                                        <LoadingButton
                                            fullWidth
                                            color="warning"
                                            variant='contained'
                                            loading={loadingStatusBuyZapping}
                                            en1dIcon={loadingStatusBuyZapping === true ? <SendIcon /> : ''}
                                            disabled={loadingStatusBuyZapping === true}
                                            loadingPosition="end"
                                            onClick={() => setOpenConfirmBuy(true)}
                                        >
                                            CANJEAR CUPON
                                        </LoadingButton>
                                    </Box> */}
                                    {/* {
                                        isCodeRevealed ? <></> : <Box pt={1}>
                                            <Typography
                                                variant='caption'
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    color: "gray"
                                                }}
                                            >
                                                MANTENGA PRESIONADO PARA REVELAR EL CUPON
                                            </Typography>
                                        </Box>
                                    } */}

                                    {/* <Box pb={2}></Box>
                                    <Typography
                                        variant='overline'
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            color: "gray"
                                        }}
                                    >
                                        CUPON VALIDO HASTA EL 30 DE SEPTIEMBRE
                                    </Typography> */}

                                    <Box pb={2}></Box>
                                    <Typography
                                        variant='caption'
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            color: "gray"
                                        }}
                                    >
                                        VIGENCIA DESDE EL 1 HASTA EL 31 DE OCTUBRE.
                                    </Typography>

                                </Box>

                            </CardContent>
                        </Card>
                        <Box pt={2}>
                            <Typography
                                variant='caption'
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    color: "gray",
                                    fontSize: '10px'
                                }}
                            >
                                LOS CÓDIGOS PODRÁN SER UTILIZADOS 1 VEZ POR DÍA (HASTA 30 VECES POR USUARIO AL MES).
                                {/* {redemptionCount} */}
                            </Typography>
                        </Box>
                        <Typography
                            variant='caption'
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                color: "gray",
                                fontSize: '10px'
                            }}
                        >
                            APLICAN SOBRE PIDE Y RETIRA Y MCDELIVERY.
                        </Typography>
                        <Typography
                            variant='caption'
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                color: "gray",
                                fontSize: '10px'
                            }}
                        >
                            NO APLICAN SOBRE PROMOCIONES.
                        </Typography>

                        <Box py={1}></Box>

                    </Container>
                </Box>
            </Grid>

        </>
    )
}